var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "phone-charge--flex phone-charge__top" }, [
        _c(
          "div",
          { staticStyle: { "flex-basis": "100%", "margin-bottom": "15px" } },
          [
            _c("p", { staticStyle: { "margin-bottom": "15px" } }, [
              _vm._v("配置管理")
            ]),
            _c(
              "div",
              {
                staticClass: "phone-charge--flex",
                staticStyle: { padding: "10px", "background-color": "#f5f5f5" }
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "phone-charge--flex-col phone-charge__config btn",
                    on: {
                      click: function($event) {
                        _vm.phoneBlockPop = true
                      }
                    }
                  },
                  [_vm._v("渠道配置")]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "phone-charge--flex-col phone-charge__config btn",
                    on: {
                      click: function($event) {
                        _vm.refundSettingPop = true
                      }
                    }
                  },
                  [_vm._v("地区维护")]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "phone-charge--flex-col phone-charge__config btn",
                    on: { click: _vm.getTips }
                  },
                  [_vm._v("温馨提示")]
                )
              ]
            )
          ]
        ),
        _c("div", { staticStyle: { "flex-basis": "100%" } }, [
          _c("div", [_vm._v("数据统计")]),
          _c("div", { staticClass: "phone-charge--flex phone-charge_census" }, [
            _c(
              "div",
              {
                staticClass: "phone-charge--flex",
                staticStyle: { "margin-bottom": "15px", "flex-basis": "100%" }
              },
              [
                _c("span", [_vm._v("数据统计时间：")]),
                _c("el-date-picker", {
                  staticStyle: { height: "37px" },
                  attrs: {
                    type: "daterange",
                    align: "right",
                    "unlink-panels": "",
                    "range-separator": "至",
                    "start-placeholder": "开始日期",
                    "end-placeholder": "结束日期",
                    "value-format": "yyyy-MM-dd HH:mm:ss",
                    "default-time": ["00:00:00", "23:59:59"],
                    "picker-options": _vm.pickerOptions
                  },
                  on: {
                    change: function($event) {
                      _vm.getSuccessData()
                      _vm.getProfitData()
                    }
                  },
                  model: {
                    value: _vm.timeList,
                    callback: function($$v) {
                      _vm.timeList = $$v
                    },
                    expression: "timeList"
                  }
                })
              ],
              1
            ),
            _c("div", { staticClass: "phone-charge--flex" }, [
              _c(
                "div",
                { staticClass: "phone-charge--flex-col phone-charge__ditem" },
                [
                  _c("span", [_vm._v("收益")]),
                  _c("span", { staticClass: "phone-charge--color" }, [
                    _vm._v(_vm._s(_vm.profitData.incomeFormat) + "元")
                  ])
                ]
              )
            ]),
            _c("div", { staticClass: "phone-charge__top-divider" }),
            _c("div", [
              _c(
                "div",
                { staticClass: "phone-charge--flex" },
                _vm._l(_vm.rateList, function(item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass:
                        "phone-charge--flex-col phone-charge__ditem phone-charge__fitem btn"
                    },
                    [
                      item
                        ? _c("p", [_vm._v(_vm._s(item.successRate))])
                        : _vm._e(),
                      item ? _c("p", [_vm._v(_vm._s(item.name))]) : _vm._e(),
                      _c(
                        "div",
                        {
                          staticClass: "phone-charge_detailed",
                          on: {
                            click: function($event) {
                              return _vm.showSuccessRate(item)
                            }
                          }
                        },
                        [
                          _vm._v("查看明细"),
                          _c("i", { staticClass: "el-icon-caret-bottom" })
                        ]
                      )
                    ]
                  )
                }),
                0
              )
            ])
          ])
        ])
      ]),
      _c(
        "el-form",
        { attrs: { inline: "", size: "mini" } },
        [
          _c(
            "el-form-item",
            { attrs: { label: "所属应用:" } },
            [
              _c("el-input", {
                staticStyle: { width: "130px" },
                attrs: { placeholder: "所属应用" },
                model: {
                  value: _vm.listFilter.app_name,
                  callback: function($$v) {
                    _vm.$set(_vm.listFilter, "app_name", $$v)
                  },
                  expression: "listFilter.app_name"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "渠道商:" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "130px" },
                  attrs: { clearable: "" },
                  model: {
                    value: _vm.listFilter.sc_type,
                    callback: function($$v) {
                      _vm.$set(_vm.listFilter, "sc_type", $$v)
                    },
                    expression: "listFilter.sc_type"
                  }
                },
                _vm._l(_vm.channelList, function(channel) {
                  return _c("el-option", {
                    key: channel.value,
                    attrs: { label: channel.label, value: channel.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "供电单位:" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "130px" },
                  model: {
                    value: _vm.listFilter.recharge_type,
                    callback: function($$v) {
                      _vm.$set(_vm.listFilter, "recharge_type", $$v)
                    },
                    expression: "listFilter.recharge_type"
                  }
                },
                [
                  _c("el-option", { attrs: { label: "全部", value: 0 } }),
                  _c("el-option", { attrs: { label: "国网", value: 1 } }),
                  _c("el-option", { attrs: { label: "南网", value: 2 } })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "订单号:" } },
            [
              _c("el-input", {
                staticStyle: { width: "130px" },
                attrs: { placeholder: "订单号" },
                model: {
                  value: _vm.listFilter.order_no,
                  callback: function($$v) {
                    _vm.$set(_vm.listFilter, "order_no", $$v)
                  },
                  expression: "listFilter.order_no"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "户号:" } },
            [
              _c("el-input", {
                staticStyle: { width: "130px" },
                attrs: { placeholder: "户号" },
                model: {
                  value: _vm.listFilter.recharge_number,
                  callback: function($$v) {
                    _vm.$set(_vm.listFilter, "recharge_number", $$v)
                  },
                  expression: "listFilter.recharge_number"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "订单状态:" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "130px" },
                  model: {
                    value: _vm.listFilter.status,
                    callback: function($$v) {
                      _vm.$set(_vm.listFilter, "status", $$v)
                    },
                    expression: "listFilter.status"
                  }
                },
                _vm._l(_vm.statusList, function(status) {
                  return _c("el-option", {
                    key: status.value,
                    attrs: { label: status.label, value: status.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "超时状态:" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "130px" },
                  model: {
                    value: _vm.listFilter.timeout_status,
                    callback: function($$v) {
                      _vm.$set(_vm.listFilter, "timeout_status", $$v)
                    },
                    expression: "listFilter.timeout_status"
                  }
                },
                [
                  _c("el-option", { attrs: { label: "全部", value: 0 } }),
                  _c("el-option", { attrs: { label: "已超时", value: 1 } }),
                  _c("el-option", { attrs: { label: "未超时", value: 2 } }),
                  _c("el-option", { attrs: { label: "即将超时", value: 3 } })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "下单时间:" } },
            [
              _c("el-date-picker", {
                staticStyle: { width: "280px" },
                attrs: {
                  type: "datetimerange",
                  "range-separator": "～",
                  "start-placeholder": "开始时间",
                  "end-placeholder": "结束时间",
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  clearable: ""
                },
                model: {
                  value: _vm.listFilter.orderTime,
                  callback: function($$v) {
                    _vm.$set(_vm.listFilter, "orderTime", $$v)
                  },
                  expression: "listFilter.orderTime"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "剩余时间:" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "130px" },
                  model: {
                    value: _vm.listFilter.leave_time,
                    callback: function($$v) {
                      _vm.$set(_vm.listFilter, "leave_time", $$v)
                    },
                    expression: "listFilter.leave_time"
                  }
                },
                [
                  _c("el-option", { attrs: { label: "全部", value: 0 } }),
                  _c("el-option", { attrs: { label: "3小时", value: 3 } }),
                  _c("el-option", { attrs: { label: "2小时", value: 2 } }),
                  _c("el-option", { attrs: { label: "1小时", value: 1 } }),
                  _c("el-option", { attrs: { label: "0.5小时", value: 0.5 } })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "" } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "64px" },
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.onFilter(false, 0)
                    }
                  }
                },
                [_vm._v("查询")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { width: "64px" },
                  attrs: { type: "default" },
                  on: {
                    click: function($event) {
                      return _vm.onFilter(true, 0)
                    }
                  }
                },
                [_vm._v("重置")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          staticClass: "phone-charge__list"
        },
        [
          _vm.selections.length
            ? _c(
                "div",
                { staticClass: "phone-charge--flex phone-charge__multi-bar" },
                [
                  _c("p", { staticStyle: { "font-size": "13px" } }, [
                    _c("i", {
                      staticClass: "el-icon-info phone-charge--color"
                    }),
                    _c("span", [_vm._v(" 已选择")]),
                    _c(
                      "span",
                      {
                        staticClass: "phone-charge--color",
                        staticStyle: { padding: "0 3px" }
                      },
                      [_vm._v(_vm._s(_vm.selections.length))]
                    ),
                    _c("span", [_vm._v("项")])
                  ]),
                  _c(
                    "div",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            disabled: !_vm.canMultiRecharge,
                            type: "text",
                            size: "small"
                          },
                          on: {
                            click: function($event) {
                              return _vm.doManualRecharge()
                            }
                          }
                        },
                        [_vm._v("批量手动充值")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            disabled: !_vm.canMultiSwitch,
                            type: "text",
                            size: "small"
                          },
                          on: {
                            click: function($event) {
                              return _vm.doSwitchToSeconds()
                            }
                          }
                        },
                        [_vm._v("批量转秒充")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            disabled: !_vm.canMultiRefund,
                            type: "text",
                            size: "small"
                          },
                          on: {
                            click: function($event) {
                              return _vm.doRefund()
                            }
                          }
                        },
                        [_vm._v("批量退款")]
                      )
                    ],
                    1
                  )
                ]
              )
            : _vm._e(),
          _c(
            "el-table",
            { staticStyle: { width: "100%" }, attrs: { data: _vm.list } },
            [
              _c("el-table-column", {
                attrs: { label: "推老板订单号", prop: "order_no", width: "110" }
              }),
              _c("el-table-column", {
                attrs: { label: "用户ID", prop: "open_user_id" }
              }),
              _c("el-table-column", {
                attrs: { label: "户号", prop: "recharge_number" }
              }),
              _c("el-table-column", {
                attrs: { label: "渠道商", prop: "sc_name" }
              }),
              _c("el-table-column", {
                attrs: { label: "供电单位", prop: "recharge_name" }
              }),
              _c("el-table-column", {
                attrs: { label: "充值金额", prop: "face_price" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [_vm._v(" " + _vm._s(row.face_price) + "元 ")]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "利润" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c("span", { style: { color: row.color } }, [
                          _vm._v(_vm._s(row.incomeFormat))
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "下单时间", prop: "created_at", width: "100" }
              }),
              _c("el-table-column", {
                attrs: { label: "订单状态", prop: "statusText" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        row.status === 3 ||
                        row.status === 1 ||
                        row.status === 10 ||
                        row.status === 11 ||
                        row.status === 14
                          ? _c("el-tag", { attrs: { size: "small" } }, [
                              _vm._v(_vm._s(row.statusText))
                            ])
                          : row.status === 5 ||
                            row.status === 6 ||
                            row.status === 13
                          ? _c(
                              "el-tag",
                              { attrs: { type: "danger", size: "small" } },
                              [_vm._v(_vm._s(row.statusText))]
                            )
                          : row.status === 2 || row.status === 4
                          ? _c(
                              "el-tag",
                              { attrs: { type: "success", size: "small" } },
                              [_vm._v(_vm._s(row.statusText))]
                            )
                          : _c(
                              "el-tag",
                              { attrs: { type: "info", size: "small" } },
                              [_vm._v(_vm._s(row.statusText))]
                            )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "子状态", prop: "subStatusText" }
              }),
              _c("el-table-column", {
                attrs: { label: "超时状态" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        row.status !== 3
                          ? _c("span", [_vm._v("--")])
                          : row.timeout_status === 2
                          ? _c("el-tag", { attrs: { size: "small" } }, [
                              _vm._v("未超时")
                            ])
                          : row.timeout_status === 3
                          ? _c(
                              "el-tag",
                              { attrs: { type: "warning", size: "small" } },
                              [_vm._v("即将超时")]
                            )
                          : row.timeout_status === 1
                          ? _c(
                              "el-tag",
                              { attrs: { type: "danger", size: "small" } },
                              [_vm._v("已超时")]
                            )
                          : _c("span", [_vm._v("--")])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "剩余时间", prop: "leftTimeFormat" }
              }),
              _c("el-table-column", {
                attrs: { label: "更新时间", prop: "updated_at", width: "100" }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "text-align": "center" } },
            [
              _c("el-pagination", {
                attrs: {
                  background: "",
                  "current-page": _vm.listFilter.page,
                  "page-sizes": [10, 20, 50, 100],
                  "page-size": _vm.listFilter.pageSize,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.listTotal
                },
                on: {
                  "update:currentPage": function($event) {
                    return _vm.$set(_vm.listFilter, "page", $event)
                  },
                  "update:current-page": function($event) {
                    return _vm.$set(_vm.listFilter, "page", $event)
                  },
                  "update:pageSize": function($event) {
                    return _vm.$set(_vm.listFilter, "pageSize", $event)
                  },
                  "update:page-size": function($event) {
                    return _vm.$set(_vm.listFilter, "pageSize", $event)
                  },
                  "current-change": _vm.getData,
                  "size-change": _vm.getData
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "明细详情",
            visible: _vm.dialogVisible,
            width: "40%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          !_vm.ratePopList.rateType
            ? _c("div", [
                _c("p", { staticStyle: { "margin-bottom": "20px" } }, [
                  _c("span", { staticStyle: { "margin-right": "50px" } }, [
                    _vm._v("全部订单数：" + _vm._s(_vm.ratePopList.order_count))
                  ]),
                  _vm._v(
                    " 全部成功订单数：" + _vm._s(_vm.ratePopList.success_count)
                  )
                ])
              ])
            : _c(
                "div",
                _vm._l(_vm.ratePopList.items, function(item, index) {
                  return _c(
                    "div",
                    { key: index, staticStyle: { "margin-bottom": "20px" } },
                    [
                      _c("p", { staticStyle: { "font-size": "16px" } }, [
                        _vm._v(
                          _vm._s(item.name) +
                            "：成功率 " +
                            _vm._s(item.successRate)
                        )
                      ]),
                      _c("p", { staticStyle: { margin: "5px 0" } }, [
                        _vm._v(
                          "成功订单数：" +
                            _vm._s(item.success_count) +
                            "，订单数：" +
                            _vm._s(item.order_count)
                        )
                      ])
                    ]
                  )
                }),
                0
              )
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.phoneBlockPop,
            title: "渠道配置",
            width: "50%"
          },
          on: {
            "update:visible": function($event) {
              _vm.phoneBlockPop = $event
            }
          }
        },
        [_c("ElectricChannelSetting")],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.refundSettingPop,
            title: "地区维护",
            width: "80%"
          },
          on: {
            "update:visible": function($event) {
              _vm.refundSettingPop = $event
            }
          }
        },
        [_c("ElectricRegionDefend")],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "提示", visible: _vm.reminderPop, width: "65%" },
          on: {
            "update:visible": function($event) {
              _vm.reminderPop = $event
            }
          }
        },
        [
          _c(
            "el-form",
            { ref: "form", attrs: { "label-width": "110px" } },
            [
              _c(
                "el-tabs",
                {
                  attrs: { type: "card" },
                  on: { "tab-click": _vm.handleClick },
                  model: {
                    value: _vm.activeName,
                    callback: function($$v) {
                      _vm.activeName = $$v
                    },
                    expression: "activeName"
                  }
                },
                _vm._l(_vm.formData, function(item, index) {
                  return _c(
                    "el-tab-pane",
                    {
                      key: index,
                      attrs: { label: item.name, name: index + "" }
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "充值确认信息" } },
                        [
                          _c("Editor", {
                            ref: "tinymce",
                            refInFor: true,
                            staticStyle: { width: "70%" },
                            attrs: { height: "200", showUpload: false },
                            model: {
                              value: item.success_notice,
                              callback: function($$v) {
                                _vm.$set(item, "success_notice", $$v)
                              },
                              expression: "item.success_notice"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "温馨提示" } },
                        [
                          _c("Editor", {
                            ref: "tinymce",
                            refInFor: true,
                            staticStyle: { width: "70%" },
                            attrs: { height: "200", showUpload: false },
                            model: {
                              value: item.use_notice,
                              callback: function($$v) {
                                _vm.$set(item, "use_notice", $$v)
                              },
                              expression: "item.use_notice"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.reminderPop = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.saveTips } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }